import { getCookie, setCookie } from "../../utils/cookies";

document.querySelector("#handleAcceptAllClick").addEventListener('click', () => {
  acceptAllCookies();
});

document.querySelector("#handleChangeSettingsClick").addEventListener('click', () => {
  document.querySelector('#step1').style.display = 'none';
  document.querySelector('#step2').style.display = 'block';
});

document.querySelector("#handleSaveSettingsClick").addEventListener('click', () => {
  const allowAllCookiesCheckbox = document.querySelector("#allow-all").checked;

  if (allowAllCookiesCheckbox) {
    acceptAllCookies();
  } else {
    acceptNecessary();
  }
});

document.addEventListener('DOMContentLoaded', () => {
  const consent = getCookie("lb-cc");
  if (!consent) {
    document.querySelector('#cookie-modal-wrapper').style.display = 'block';
  } else if (consent !== "false") {
    acceptAllCookies();
  }
});

function acceptAllCookies() {
  consentCookies();
  setCookie("lb-cc", new Date().toLocaleString(), 180);
  document.querySelector('#cookie-modal-wrapper').style.display = 'none';
}

function acceptNecessary() {
  setCookie("lb-cc", "false", 180);
  document.querySelector('#cookie-modal-wrapper').style.display = 'none';
}