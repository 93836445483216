function removeLazyClassesInView() {
  const elements = document.querySelectorAll(".lazy");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    var rect = element.getBoundingClientRect();
    if (rect.top - window.scrollY < 0) {
      element.classList.remove("lazy");
    }
  }
}

window.onload = function() {
  removeLazyClassesInView();
};

document.addEventListener("scroll", removeLazyClassesInView);
