window.loadTrustboxes = function () {
  function checkScrollPosition() {
    const { scrollY } = window;
    const nav = document.getElementById("nav");

    nav.style.top = Math.max(25 - scrollY, 0) + "px";
  }

  //   const header = document.getElementById("trustbox-header");
  //   header.style.display = "block";
  //   window.Trustpilot.loadFromElement(header);

  const reviews = document.getElementById("trustbox-reviews");
  reviews.style.display = "block";
  window.Trustpilot.loadFromElement(reviews);

  const offline = document.getElementById("offline-reviews");
  offline.style.display = "none";

  //   if (window.location.hostname.endsWith(".se")) {
  //     const nav = document.getElementById("nav");
  //     nav.style.top = "25px";
  //     window.addEventListener("scroll", checkScrollPosition);
  //   }
};
