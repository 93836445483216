import { getCookie } from "../../utils/cookies";

let authenticated = null;

function isAuthenticated() {
  if (authenticated === null) {
    const cookieVal = getCookie("isAuthenticated");
    authenticated = cookieVal === "true";
  }
  return authenticated;
}

export { isAuthenticated };
