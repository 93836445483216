import "./shared/navbar";
import "./shared/lazyLoading";
import "./shared/cookieModal";
import "whatwg-fetch";
import "promise-polyfill/src/polyfill";
import "./home/blog";
import "./home/inspiration";
import "./home/mobileCta";
import "./home/hero";
import "./home/trustpilotbanner"

import { isAuthenticated } from "./shared/authorization";
import { render } from "sass";
import { emitEvent, queueEvent, eventTypes } from "./events";

if (isAuthenticated()) {
  window.dataLayer.push({
    isAuthenticated: 1,
    isMobileUser: window.LBConfig.origin === "Mobile",
  });
}

emitEvent(eventTypes.PAGEVIEW, 'Home', {
  referrer: document.referrer,
  search: document.location ? document.location.search : undefined
});

function handleCTAClick(ctaId, href) {
  try {
    queueEvent(eventTypes.CLICK, "Home CTA", { id: ctaId });
  } catch {
    
  }
  window.location = href;
}

const ctaElements = document.querySelectorAll(`[data-cta-id]`);
for (const el of ctaElements) {
  const ctaId = el.getAttribute('data-cta-id');

  el.addEventListener('click', function(e) {
    e.preventDefault();
    handleCTAClick(ctaId, el.getAttribute('href'));
  });
  
  el.addEventListener('touchstart', function(e) {
    e.preventDefault();
    handleCTAClick(ctaId, el.getAttribute('href'));
  });
}

document.getElementById("year").innerHTML = new Date().getFullYear();