import { isAuthenticated } from "../shared/authorization";

(function() {
  function checkScrollPosition() {
    const { scrollY } = window;
    const floatingCta = document.getElementById("js-mobile-cta");

    if (
      scrollY > 320 &&
      !(
        window.innerHeight + scrollY >
        window.offsetTop + floatingCta.offsetHeight
      )
    ) {
      floatingCta.classList.remove("relative");
    } else {
      floatingCta.classList.add("relative");
    }
  }

  if (!isAuthenticated()) {
    window.addEventListener("scroll", checkScrollPosition);
  }
})();
